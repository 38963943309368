@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css */
.language-dropdown {
    position: relative;
  }
  
  .language-dropdown button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .language-dropdown button:hover {
    background-color: #f0f0f0;
  }
  
  .language-dropdown .dropdown-menu {
    position: absolute;
    right: 0;
    margin-top: 5px;
    width: 200px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .language-dropdown .dropdown-menu button {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    color: #333;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .language-dropdown .dropdown-menu button:hover {
    background-color: #f0f0f0;
  }